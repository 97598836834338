import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import * as Sentry from '@sentry/nextjs';
import UTM from 'utm-params-saver';

import 'semantic-ui-css/components/reset.css';
import 'semantic-ui-css/components/site.css';
import 'semantic-ui-css/components/sidebar.css';
import 'semantic-ui-css/components/accordion.css';
import 'semantic-ui-css/components/icon.css';
import 'semantic-ui-css/components/input.css';
import 'semantic-ui-css/components/list.css';
import 'semantic-ui-css/components/menu.css';
import 'semantic-ui-css/components/image.css';
import 'semantic-ui-css/components/placeholder.css';
import 'semantic-ui-css/components/card.css';
import 'semantic-ui-css/components/button.css';
import 'semantic-ui-css/components/label.css';
import 'semantic-ui-css/components/checkbox.css';
import 'semantic-ui-css/components/dropdown.css';
import 'semantic-ui-css/components/transition.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../styles/index.css';
import '../styles/theme.css';
import '../styles/semantic.css';
import '../styles/form.css';

const isProduction = process.env.ENVIRONMENT === 'production';

function VideomatikLandingPage({ Component, pageProps }) {
  useEffect(() => {
    const UTMParams = UTM.parse();
    const hasUTMParams = Object.keys(UTMParams).length > 0;
    if (hasUTMParams) {
      UTM.save(UTMParams);
    }
  });

  return (
    <>
      <Sentry.ErrorBoundary>
        <Component {...pageProps} />
      </Sentry.ErrorBoundary>
      {isProduction && (
        <>
          <Script
            src="scripts/facebookpixel.js"
            strategy="afterInteractive"
            lazyOnload
          />
          <Script
            src="scripts/googletagmanager.js"
            strategy="afterInteractive"
            lazyOnload
          />
          <script src="https://player.vimeo.com/api/player.js" />
          <noscript>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-T22C2KK"
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>
        </>
      )}
    </>
  );
}

VideomatikLandingPage.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.object,
};

export default VideomatikLandingPage;
